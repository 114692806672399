.HomePage {
  text-align: center;
}

.HomePage-logo {
  height: 40vmin;
  pointer-events: none;
}

.HomePage-header {
  background-color: #EED6C4;
  min-height: 100vh;
  
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  
  font-size: calc(10px + 2vmin);
  color: #4F091D;
}

.shorten-url button {
  background-color: #4F091D;
  color: #f5f5f5;
}

.shourt-url-history {
  background-color: #ffffff;
}

.shourt-url-history .url {
  width: 250px;
  text-align: left;
  text-overflow: ellipsis;
  overflow-x: hidden;
}